@import "~normalize.css/normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
/*@import "~@blueprintjs/timezone/lib/css/blueprint-timezone.css";*/
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #293742;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header.header0.home-page-wrapper > div.home-page {
  max-width: 100vw;
}

.ant-modal-header {
  border-bottom: 0 !important;
}

.ant-message-notice-content,
.ant-modal-content,
.ant-modal-content .ant-modal-header {
  background-color: #30404d !important;
}

.ant-modal-content .ant-modal-close-x,
.ant-modal-content .ant-modal-title {
  color: white !important;
}
